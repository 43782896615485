// import modules
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import action
import { increment } from './action.es6';

/**
 *  Container Class
 *  @version 2016/05/02
 *  @author ryo.aso
 */
class Container extends Component {
  /**
   *  Click Event
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  handleClick() {
    this.props.dispatch(increment(this.props.fuga));
  }

  /**
   *  表示処理
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  render() {
    const { increment } = this.props;

    return (
      <div>
        <span>{increment.fuga}</span>
        <button onClick={this.handleClick.bind(this)}>増加</button>
      </div>
    );
  }
}

/**
 * State to Props
 * @const {object}
 */
const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(Container);
