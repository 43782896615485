import React, { useState, useEffect } from 'react';

const FollowBanner = (props) => {
  const device = props.device;
  const url = props.url;
  const [isReached, setIsReached] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  /**
   * 画面をスクロール
   */
  const onScroll = () => {
    const topPosition = 120;
    const scrollPosition = window.pageYOffset;

    if (device === 'sp' || scrollPosition >= topPosition) {
      setIsReached(true);
    } else {
      setIsReached(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  });
  return (
    <div className={`${isReached && !isClosed ? 'c-followBanner' : 'c-followBannerDisabled'}`}>
      <div className='c-followBanner__close' onClick={() => setIsClosed(true)}>
        ×
      </div>
      <a href={url} target='_blank' rel='noopener noreferrer' className='c-followBanner__link'>
        <img
          className='c-followBanner__img'
          src={`${device === 'pc' ? 'https://codecampkids.s3.ap-northeast-1.amazonaws.com/products/component/img_follow_banner01.png' : 'https://codecampkids.s3.ap-northeast-1.amazonaws.com/products/component/img_follow_banner01_sp.png'}`}
        />
      </a>
    </div>
  );
};
export default FollowBanner;
