import React from 'react';

/* Rails 用セキュリティトークンを React 側で仕込む際に用いるコンポーネント */
const AuthenciticyToken = () => {
  const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
  if (token == null) return null;

  return <input type='hidden' name='authenticity_token' value={token} />;
};

export default AuthenciticyToken;
