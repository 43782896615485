// import modules.
import React from 'react';

// css
import style from './style.sass';

/**
 *  Panels Class
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export default class Application extends React.Component {
  /**
   *  コンストラクタ
   *  @version 2017/01/05
   *  @author ryo-as
   */
  constructor(props) {
    super(props);

    // state
    this.state = {
      ...props,
      loaded: false,
    };
  }

  /**
   *  初期描画直後に一度だけ実行
   *  @version 2017/01/05
   *  @author ryo-as
   */
  componentDidMount() {
    this.refs.video.addEventListener('loadeddata', () => {
      this.setState({ loaded: true });
    });
  }

  /**
   *  表示処理
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  render() {
    const { image, video, loaded } = this.state;

    const background = {
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
    };

    return (
      <div className={style.player}>
        <i style={background} className={style[`player__image${loaded ? '--hidden' : ''}`]} />
        <video src={video} ref='video' autoPlay loop muted className={style.player__video} />
      </div>
    );
  }
}
