// スクロールすると上からヘッダーが出て来る
window.addEventListener(
  'load',
  () => {
    const widgetObject = document.getElementById('js-fixedHeader');
    const targetSection = document.getElementById('js-header');

    const SCROLL = targetSection.offsetHeight;

    if (widgetObject) {
      document.addEventListener('scroll', () => {
        widgetObject.dataset.show = window.pageYOffset > SCROLL;
      });
    }
  },
  false,
);
