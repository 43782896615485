/**
 *  Method Name
 *  @const {object}
 */
export const INCREMENT = 'INCREMENT';

/**
 *  Action Method
 *  @const {object}
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export const increment = () => ({
  type: INCREMENT,
});
