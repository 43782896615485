import Rails from '@rails/ujs';
Rails.start();

import mount from 'components/mount';

// アラート
import Alert from 'products/components/alert';
// 体験イベントに参加
import Entry from 'products/components/entry';
// お問い合わせ
import Contact from 'products/components/contact';
// 遅延読み込み
import LazyLoadVideo from 'products/components/lazy_load_video';
// franchise お問い合わせ
import FranchiseInquiry from 'products/components/franchise_inquiry';
// franchise 資料ダウンロード
import FranchiseDownload from 'products/components/franchise_download';
// Redux Sample
import Sample from 'products/components/sample';
// 動画自動再生
import VideoAutoplay from 'products/components/video_autoplay';
// トップに戻るボタン
import TopButton from 'products/components/top_button';
// 追従パーツ
import FollowBanner from 'products/components/follow_banner';

mount({
  Alert,
  Entry,
  Contact,
  LazyLoadVideo,
  FranchiseInquiry,
  FranchiseDownload,
  Sample,
  VideoAutoplay,
  TopButton,
  FollowBanner,
});

// 上からヘッダーが降りてくる
import 'products/scripts/fixed_header.es6';

// SPのメニュー開く
import 'products/scripts/drawer.es6';

// ページ遷移後スクロール
import 'products/scripts/transited_scroll.es6';
