/**
 * ロケール - 曜日
 * @const {array}
 */
export const LOCALE_DAY_OF_WEEK = ['日', '月', '火', '水', '木', '金', '土'];

/**
 * 知ったきっかけ
 * @const {Array}
 */
export const OPPORTUNITIES = [
  'インターネットで検索',
  'インターネットの広告',
  'FacebookやTwitter等のSNS',
  '折り込みチラシ',
  '知人の紹介',
  'その他',
];

/**
 * 学年
 * @const {Array}
 */
export const GRADES = [
  '小学1年生',
  '小学2年生',
  '小学3年生',
  '小学4年生',
  '小学5年生',
  '小学6年生',
  '中学1年生',
  '中学2年生',
  '中学3年生',
];
