// import modules.
import React from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

// import view component.
import Container from './container.es6';
// import reducer.
import reducer from './reducer.es6';
// import action
import { initialize } from './action.es6';

/**
 * Create Store
 * @const {object}
 */
const store = configureStore(reducer);

/**
 *  Panels Class
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export default class Contact extends React.Component {
  /**
   *  初期描画直後に一度だけ実行
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  UNSAFE_componentWillMount() {
    store.dispatch(initialize(this.props));
  }

  /**
   *  表示処理
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  render() {
    return (
      <Provider store={store}>
        <Container />
      </Provider>
    );
  }
}
