// ドロワーメニュー開く
window.addEventListener('load', () => {
  const body = document.body;
  const targets = document.querySelectorAll('.js-drawerOpen');

  targets.forEach((target) => {
    target.addEventListener('click', () => {
      body.dataset.menuOpen = body.dataset.menuOpen == 'true' ? 'false' : 'true';
    });
  });
});
